<template>
  <div style="width: 100%; background: #f1f1f1">
    <el-card class="title-card">
      <div slot="header" class="clearfix">
        <el-avatar class="title-image" :src="user.headImg"></el-avatar>
        <span class="name-text">毛不易</span>
        <el-button
          class="exit"
          icon="el-icon-switch-button"
          @click="exitClick"
          type="primary"
          plain
          >退出</el-button
        >
      </div>
      <div class="information">
        <el-avatar class="content-image" :src="user.headImg"></el-avatar>
        <div class="infor-text">
          <p>
            <span class="count-text">您好</span>
          </p>
          <p>
            <span class="count-text">账号：{{ user.mobileNo }}</span>
          </p>
        </div>
        <el-row class="button-content">
          <el-col><el-button icon="el-icon-user" circle></el-button></el-col>
          <span class="button-font">我的账户</span>
        </el-row>
        <el-row class="button-content">
          <el-col
            ><el-button icon="el-icon-message" circle></el-button>
          </el-col>
          <span class="button-font">帮助中心</span>
        </el-row>
        <el-row class="button-content">
          <el-col
            ><el-button class="button-in" icon="el-icon-document" circle></el-button
          ></el-col>
          <span class="button-font-in">意见反馈</span>
        </el-row>
        <el-row class="button-content">
          <el-col><el-button icon="el-icon-setting" circle></el-button></el-col>
          <span class="button-font">账户设置</span>
        </el-row>
      </div>
    </el-card>

    <div class="feedback">
      <el-input
        type="textarea"
        v-model="inputText"
        placeholder="有任何问题或建议都可以在这里告诉我们，以便我们做的更好～"
        :rows="9"
      ></el-input>
      <el-button class="submit-button" type="primary">提交</el-button>
    </div>

    <div class="history">
      <span class="history-title">反馈历史</span>
      <div class="history-content">
        <p>
          <span class="time">2023-06-23 14:23:54</span>
        </p>
        <p>
          <span class="history-feedback"
            >在药品生产与研发领域的药物语境中，“Declaration and
            Registration”指的是药品的申报和注册过程。这是指制药公司在准备上市新的药物或药品之前需要进行的一系列程序。在申报阶段，制药公司需提交相关文件和资料，包括药物的成分、制造工艺、药理学和临床试验数据等，以向监管机构声明该药物的安全性和有效性。在注册阶段，制药公司需要向相应的监管机构提交申请文件，并经过审查、评估和批准过程，以获得。</span
          >
        </p>
      </div>

      <div class="history-content">
        <p>
          <span class="time">2023-06-23 14:23:54</span>
        </p>
        <p>
          <span class="history-feedback"
            >在申报阶段，制药公司需提交相关文件和资料，包括药物的成分、制造工艺、药理学和临床试验数据等，以向监管机构声明该药物的安全性和有效性。在注册阶段，制药公司需要向相应的监管机构提交申请文件，并经过审查、评估和批准过程，以获得。</span>
        </p>
      </div>

      <div class="history-content">
        <p>
          <span class="time">2023-06-23 14:23:54</span>
        </p>
        <p>
          <span class="history-feedback"
            >在注册阶段，制药公司需要向相应的监管机构提交申请文件，并经过审查、评估和批准过程，以获得。</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { loginOut, userInfo } from "@/api/index";
import { wxPayPre, queryOrder, getOrderList } from "@/api/order";
import QRCode from "qrcode";
import App from "../App.vue";

export default {
  components: { App },
  data() {
    return {
      urls: [
        "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg",
      ],
      user: {
        money: null,
        mobileNo: null,
        headImg: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      userInfo().then((response) => {
        this.user = response.MobileBody;
        if (this.user.headImg == null) {
          this.user.headImg =
            "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg";
        }
      });
      getOrderList(this.queryParams).then((response) => {
        this.moneyData = response.MobileBody.orderList;
      });
    },
  },
};
</script>

<style scoped>
.title-card {
  width: 100%;
  background-color: #fafafa;
}

.box-card {
  margin: 20px;
  width: 97%;
}

.exit {
  padding: 3px 0;
  width: 80px;
  height: 30px;
  border-radius: 5px;
}

.clearfix {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.title-image {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
}

.name-text {
  font-size: 14px;
  margin-right: 30px;
}

.content-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
}

.button-content {
  margin-right: 0;
}

.button-font {
  font-size: 10px;
}

.button-in {
  color: #1f75e8;
}

.button-font-in {
  font-size: 10px;
  color: #1f75e8;
}

.information {
  height: 40px;
  margin: 5px;
  display: flex;
  padding: 15px;
}

.infor-text {
  width: 65%;
  margin: -10px 0 0 5px;
  font-size: 12px;
}

.count-text {
  margin-bottom: -10px;
}


.feedback {
  margin: 20px;
}

.submit-button {
  float: right;
  margin-top: 10px;
  width: 100px;
  height: 40px;
  background: #1a73e8;
  border-radius: 5px;
}

.history {
  background: #ffffff;
  border-radius: 6px;
  margin: 70px 20px;
  padding: 20px;
}

.history-title {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 25px;
  margin: 20px;
}

.history-content {
  margin: 30px 20px;
}

.time {
  width: 1114px;
  height: 30px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}

.history-feedback {
  width: 1114px;
  height: 90px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 30px;
}
</style>
