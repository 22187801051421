import Vue from 'vue';
import App from './App.vue';
import router from './router';
import {Lazyload} from 'vant';
import 'lib-flexible';
import { Toast } from 'vant';
import store from './store';
Vue.use(Toast);
//set NODE_OPTIONS=--openssl-legacy-provider

/*引入axios*/
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios,axios);
//Vue.prototype.$axios = axios;

Vue.use(Lazyload);

/*引入Vant*/
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

/*引入element-ui*/
import ElementUI from 'element-ui';
import { i18n } from '@/i18n/index.js';
import { globalComponentSize } from '@/utils/componentSize.js';
import 'element-ui/lib/theme-chalk/index.css';
import Particles from 'vue-particles';
import 'element-ui/lib/theme-chalk/index.css';
import '@/theme/index.scss';
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value), size: globalComponentSize });
Vue.use(Particles);

Vue.config.productionTip = false;
Vue.prototype.bus = new Vue();
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
