<template>
    <!-- <el-container>
        <el-aside>
            <div class="chat-title">
                <h1 style="font-size: 14px;">
                    {{this.toolName}}
                </h1>
                <hr>
                <div>
                    <strong>在底部输入框输入想要提问或查询内容，如果回答的结果不满意，可以对提问或查询内容的文字进行细化&优化后重新提交，这样可以获得更准确的回复。</strong>
                </div>
            </div>

            <div class="chat-container">
                <div class="chat-messages" ref="messageContainer" @scroll="handleScroll" v-bind:style="divStyle" @mouseenter="showScrollbarFun" @mouseleave="hideScrollbar">
                    <el-card class="message" :class="{ 'auto-reply-message': message.isAutoReply }"  v-for="message in messages" :key="message.id">
                        <div class="message-content">
                            <span :class="{ 'auto-reply': message.isAutoReply }">{{ message.content }}</span>
                        </div>


                        <el-collapse accordion style="margin-top:10px" v-if="message.isShow" @change="changeList(message.nodesList)">
                          <el-collapse-item v-for="(item,index) in message.nodesList" :key="index" @click.native="changBottom(item)">
                            <template slot="title">
                              <i :class="item.down?'el-icon-caret-right':'el-icon-caret-bottom'"></i>{{[index+1]}}{{ item.title }}
                            </template>
                            <div>{{ item.doc }}</div>
                          </el-collapse-item>
                        </el-collapse>

                        <div class="message-time">{{ message.time }}</div>
                        
                    </el-card>
                  


                </div>
                <div class="chat-input">
                    <el-input v-model="inputMessage" placeholder="请输入消息" @keyup.native.enter="sendMessage" autofocus/>
                    <el-button :loading="loading" type="primary" @click="sendMessage">发送</el-button>
                </div>
            </div>
        </el-aside>
        <el-main>
            <div>
                <div class="other">
                    <div class="text-container">
                        <span class="trade-text-zh" style="font-size: 28px;color: #ee0a24">广告位招租</span>
                    </div>
                </div>
                <div class="trade" @click="redirectToPage">
                    <img class="trade-image" src="@/assets/img_fanyi@2x.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">翻译助手</span>
                    </div>
                </div>
                <div class="devia" v-if="chatForm.classId !== '1'"  @click="redirectToTools(1)">
                    <img class="devia-image" src="@/assets/img_pianchazhushou@2x.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">GMP助手</span>
                    </div>
                </div>
                <div v-if="chatForm.classId !== '2'" class="other" @click="redirectToTools(2)">
                    <img class="other-image" src="@/assets/thumb3.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">DI&CSV助手</span>
                    </div>
                </div>
                <div class="trade" v-if="chatForm.classId !== '3'" @click="redirectToTools(3)">
                    <img class="trade-image" src="@/assets/thumb2.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">GSP助手</span>
                    </div>
                </div>
                <div class="devia" v-if="chatForm.classId !== '4'"  @click="redirectToTools(4)">
                    <img class="devia-image" src="@/assets/thumb1.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">无菌保障助手</span>
                    </div>
                </div>
                <div class="other" v-if="chatForm.classId !== '5'" @click="redirectToTools(5)">
                    <img class="other-image" src="@/assets/img_qita@2x.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">MAH助手</span>
                    </div>
                </div>
                <div class="contact-us" style="text-align: center">
                    <el-button type="primary" @click="showContactModal">联系我们</el-button>
                    <div class="qr-code-container">
                        <el-dialog class="qr-code-dialog" title="联系我们" :visible.sync="contactModalVisible" @close="closeContactModal">
                            <img class="qr-code-image" src="https://gxpresource.oss-cn-shenzhen.aliyuncs.com/contact.jpg" alt="二维码图片">
                        </el-dialog>
                    </div>
                </div>
            </div>
        </el-main>
    </el-container> -->

    <div class="chat">
        <div class="flex-warp">
            <div class="chat-left">
                <div class="chat-title">
                    <h1 style="font-size: 14px;">
                        {{this.toolName}}
                    </h1>
                    <hr>
                    <div class="mt-10">
                        <strong>在底部输入框输入想要提问或查询内容，如果回答的结果不满意，可以对提问或查询内容的文字进行细化&优化后重新提交，这样可以获得更准确的回复。</strong>
                    </div>
                </div>
                <div class="chat-container">
                    <div class="chat-messages" ref="messageContainer" @scroll="handleScroll"  @mouseenter="showScrollbarFun" @mouseleave="hideScrollbar">
                        <el-card class="message" :class="{ 'auto-reply-message': message.isAutoReply }"  v-for="message in messages" :key="message.id">
                            <div class="message-content">
                                <span :class="{ 'auto-reply': message.isAutoReply }" style="white-space: pre-wrap;">{{ message.content }}</span>
                            </div>
                            <el-collapse accordion style="margin-top:10px" v-model="activeNames"  v-if="message.isShow" >
                                <el-collapse-item v-for="(item,index) in message.nodesList" :key="index" :name="item.nodeId">
                                    <template slot="title">
                                      <i :class="judgeActive(item.nodeId)!==-1?'el-icon-caret-bottom':'el-icon-caret-right'"></i>{{[index+1]}}{{ item.title }}
                                    
                                    </template>
                                    <div>{{ item.doc }}</div>
                                </el-collapse-item>
                            </el-collapse>
                            <div class="message-time">{{ message.time }}</div>
                        </el-card>
                       
                    </div>
                  
                </div>

              
            </div>
             <!-- 广告导航栏 -->
            <div class="chat-right">
                <!-- <div class="chat-right-header">
                    <div class="chat-right-title">
                        广告位招租
                    </div>
                </div> -->
                <div>
                <div class="other">
                    <div class="text-container">
                        <span class="trade-text-zh" style="font-size: 12px;color: #ee0a24">广告位招租</span>
                    </div>
                </div>
                <div class="trade" @click="redirectToPage">
                    <img class="trade-image" src="@/assets/img_fanyi@2x.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">翻译助手</span>
                    </div>
                </div>
                <div class="devia" v-if="chatForm.classId !== '1'"  @click="redirectToTools(1)">
                    <img class="devia-image" src="@/assets/img_pianchazhushou@2x.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">GMP助手</span>
                    </div>
                </div>
                <div v-if="chatForm.classId !== '2'" class="other" @click="redirectToTools(2)">
                    <img class="other-image" src="@/assets/thumb3.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">DI&CSV助手</span>
                    </div>
                </div>
                <div class="trade" v-if="chatForm.classId !== '3'" @click="redirectToTools(3)">
                    <img class="trade-image" src="@/assets/thumb2.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">GSP助手</span>
                    </div>
                </div>
                <div class="devia" v-if="chatForm.classId !== '4'"  @click="redirectToTools(4)">
                    <img class="devia-image" src="@/assets/thumb1.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">无菌保障助手</span>
                    </div>
                </div>
                <div class="other" v-if="chatForm.classId !== '5'" @click="redirectToTools(5)">
                    <img class="other-image" src="@/assets/img_qita@2x.png" />
                    <div class="text-container">
                        <span class="trade-text-zh">MAH助手</span>
                    </div>
                </div>
                <div class="contact-us" >
                    <el-button type="primary" size="mini" @click="showContactModal">联系我们</el-button>
                    <div class="qr-code-container">
                        <el-dialog class="qr-code-dialog" title="联系我们" :visible.sync="contactModalVisible" @close="closeContactModal">
                            <div class="qr-code-div">
                                <img class="qr-code-image" :src="srcImg" alt="二维码图片">
                            </div>
                        </el-dialog>
                    </div>
                </div>
            </div>
                
               
            </div>
        </div>
        
        <div class="chat-bottom">
            <div class="chat-bottom-problem">
                <div class="chat-bottom-title">
                    <el-row :gutter="15">
                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                            猜你想问:
                        </el-col>
                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="chat-bottom-right">
                            <el-button type="primary" size="mini" @click="refreshQuery">刷新</el-button>
                        </el-col>
                    </el-row>
                </div>
                <!-- <el-row :gutter="15">
                    <el-col class="one-text-overflow" :xs="6" :sm="6" :md="6" :lg="6" :xl="6" v-for="(item,index) in problemList" :key="index">
                         {{ item.title }}
                    </el-col>
                </el-row> -->
                <el-carousel height="20px" direction="vertical" indicator-position="none">
                    <el-carousel-item v-for="(item,index) in problemList" :key="index">
                        <el-link  type="primary" :underline="false" @click="cliclkProblem(item.problem)">{{index+1}}：{{ item.problem }}</el-link>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="chat-bottom-button">
                <el-input v-model="inputMessage" placeholder="请输入消息" @keyup.native.enter="sendMessage" autofocus/>
                <el-button :loading="loading" style="margin-left: 10px;" type="primary" @click="sendMessage">发送</el-button>
            </div>
          
        </div>
       

    </div>

</template>

<script>
    import { doChat,chatHistory,getAssistantClassTipsByClassId,getContactUsUrl,getRecordNodesList } from "@/api/index";
    import axios from 'axios';

    export default {
        // mounted() {
        //     console.log(this.$route.params.name); // 输出：John
        // },
        data() {
            return {
              
                contactModalVisible: false,
                messages: [],
                loading: false,
                inputMessage: '',
                showScrollbar: false,
                divStyle: {
                    overflow: 'hidden'
                },
                activeName:"",
                strJson:null,
                nodesList: [],
                clickNodesList:[],
                isShow: true,
                isCalShow: false,
                toolId:this.$route.params.name,
                toolName:null,
                chatForm: {
                    classId: this.$route.params.name,
                    content: null,
                    createTime: this.getCurrentDate(),
                    pageSize:5
                },
                chatFormNodes: {
                  recordId: null,
                },
                chatList:[],
                streamingData: [],
                strData:"",
                activeNames:"",
                problemList:[],
                srcImg:"",
            };
        },
        
        created() {
            this.getToolName();
            this.initMessageHistory(true);
            this.getAssistantClassTipsByClassId();
            
        },
        mounted(){
            let list = document.querySelector(".chat-messages");
            list.addEventListener('touchmove',e=>e.stopPropagation(),false)

        },
        methods: {
            //刷新问题
            refreshQuery(){
              this.getAssistantClassTipsByClassId();
            },
           
            judgeActive(data){
                let a =this.activeNames+"";
                let b = data + "";
                return a.indexOf(b);
            },

            cliclkProblem(value){
                this.inputMessage = value;
            },
          
            redirectToPage() {
                this.$router.push('/Application'); // 跳转到目标页面
                this.$router.go(0);
            },
            redirectToTools(obj) {
                this.$router.push({
                    path: '/Chat/'+obj,
                });
                this.$router.go(0);
            },
            handleScroll() {
                const messageList = this.$refs.messageContainer;
                const scrollTop = messageList.scrollTop;
                if (scrollTop === 0) {
                    this.initMessageHistory(false);
                }
            },
            getToolName() {
                if (this.toolId === '1') {
                    this.toolName ="GMP助手";
                }
                if (this.toolId === '2') {
                    this.toolName ="DI&CSV助手";
                }
                if (this.toolId === '3') {
                    this.toolName ="GSP助手";
                }
                if (this.toolId === '4') {
                    this.toolName ="无菌保障助手";
                }
                if (this.toolId === '5') {
                    this.toolName ="MAH助手";
                }
                if (this.toolId === '7') {
                    this.toolName ="药物警戒助手";
                }
                this.chatForm.classId = this.toolId;
            },
            initMessageHistory(flag) {
                chatHistory(this.chatForm).then((response) => {
                    // console.log(response);
                    this.chatList = response.MobileBody.recordList;
                    // console.log(this.chatList);
                    for (let chat of this.chatList) {
                        const replyMessage = {
                            id: chat.id+"1",
                            content: chat.reply,
                            time: chat.createTime,
                            isAutoReply: true,
                            nodesList: chat.nodesList,
                            isShow: true,
                        };
                        const askMessage = {
                            id: chat.id+"2",
                            content: chat.content,
                            time: chat.createTime,
                            isAutoReply: false,
                            nodesList: [],
                            isShow: false,
                        };
                        this.messages.unshift(replyMessage);
                        this.messages.unshift(askMessage);
                    }
                    const lastItem = this.chatList[this.chatList.length - 1];
                    if (lastItem != null) {
                        this.chatForm.createTime = lastItem.createTime;
                    }
                    if(flag) {
                        this.scrollToBottom(); //
                    }
                    console.log(this.messages,'messages======')
                }).finally(() => {

                });
            },

            getAssistantClassTipsByClassId() {
                let params = {
                    classId:this.toolId
                }
                getAssistantClassTipsByClassId(params).then((response) => {
                    // console.log(response,'response====');
                    this.problemList = response.MobileBody.tipsList
                   
                }).finally(() => {

                });
            },
            sendMessage() {
                this.streamingData = [];
                if (this.inputMessage.trim() !== '') {
                    this.loading = true;
                    const newMessage = {
                        id: Date.now(),
                        content: this.inputMessage,
                        time: this.getCurrentDate(),
                        isAutoReply: false
                    };
                    this.messages.push(newMessage);
                    this.isCalShow=true;
                    this.scrollToBottom(); //
                    this.chatForm.content = this.inputMessage.trim();
                    this.inputMessage = '';
                    this.nodesList = [];
                    let start = true;
                    fetch("http://api.gxpcode.com/assistant/chatConsultation", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "X-Gxpcode-Access-Token":localStorage.getItem('token')
                        },
                        body: JSON.stringify(this.chatForm)
                    }).then(response => response.body)
                        .then(stream => {
                            const reader = stream.getReader();
                            const processStream = () => {
                                reader.read().then(({ done, value }) => {
                                    if (done) {
                                        return;
                                    }
                                    console.log(value,done,'22222')
                                    const bytes = new Uint8Array(value);
                                    const decoder = new TextDecoder();
                                    // const str = decoder.decode(bytes).replace(/\n/g, "");
                                    const str = decoder.decode(bytes);

                                    if(str=='-7'){
                                      location.href = '/Login';
                                    }

                                    if(str.includes("dividerLine")){
                                      const strJson = str.split("dividerLine")[1];
                                      const strBefore = str.split("dividerLine")[0];
                                      console.log(strJson,strBefore,'包含')
                                      if(strBefore!=null && strBefore!=''){
                                        this.streamingData.push(strBefore);
                                        this.strData = this.streamingData.join("");
                                        if (start) {
                                          const replyMessage = {
                                            id: Date.now(),
                                            content: this.strData,
                                            time: this.getCurrentDate(),
                                            isAutoReply: true,
                                            nodesList: this.nodesList,
                                            isShow: true
                                          };
                                          this.messages.push(replyMessage);
                                          start = false;
                                        } else {
                                          this.strData = this.strData.replace("根据提供的背景信息，", "");
                                          this.strData = this.strData.replace("根据提供的上下文信息，", "");
                                          this.messages[this.messages.length-1].content = this.strData;
                                        }
                                      }

                                      if(strJson!=null && strJson!=''){
                                        this.isCalShow = true;
                                        this.chatFormNodes.recordId = strJson;
                                        getRecordNodesList(this.chatFormNodes).then((response) => {
                                          this.nodesList = response.MobileBody.nodesList;
                                          this.messages[this.messages.length-1].nodesList = this.nodesList;
                                          this.scrollToBottom(); //
                                        }).finally(() => {

                                        });
                                        this.scrollToBottom(); //
                                      }
                                    }else{
                                      this.streamingData.push(str);
                                      this.strData = this.streamingData.join("");
                                      console.log(this.strData,'未包含')
                                      if (start) {
                                        const replyMessage = {
                                          id: Date.now(),
                                          content: this.strData,
                                          time: this.getCurrentDate(),
                                          isAutoReply: true,
                                          nodesList: this.nodesList,
                                          isShow: true
                                        };
                                        this.messages.push(replyMessage);
                                        start = false;
                                      } else {
                                        this.strData = this.strData.replace("根据提供的背景信息，", "");
                                        this.strData = this.strData.replace("根据提供的上下文信息，", "");
                                        this.messages[this.messages.length-1].content = this.strData;
                                        this.messages[this.messages.length-1].nodesList = this.nodesList;
                                      }
                                    }
                                    // //判断当前str是否是json格式，如果是则直接输出，否则转json格式输出
                                    // if(!this.checkJson(str)){//是
                                    //   this.streamingData.push(str);
                                    //   this.strData = this.streamingData.join("");
                                    //   if (start) {
                                    //     const replyMessage = {
                                    //       id: Date.now(),
                                    //       content: this.strData,
                                    //       time: this.getCurrentDate(),
                                    //       isAutoReply: true
                                    //     };
                                    //     this.messages.push(replyMessage);
                                    //     start = false;
                                    //   } else {
                                    //     this.strData = this.strData.replace("根据提供的背景信息，", "");
                                    //     this.strData = this.strData.replace("根据提供的上下文信息，", "");
                                    //     this.messages[this.messages.length-1].content = this.strData;
                                    //   }
                                    // }else{
                                    //   // this.answerObject = JSON.parse(str);
                                    //   this.nodesList = this.setJsonData(str);
                                    // }
                                    // console.log(str);
                                    this.scrollToBottom(); //
                                    processStream();
                                    // // 休眠100毫秒
                                    // setTimeout(() => {
                                    //
                                    // }, 100);
                                });
                            };
                            processStream();
                        })
                        .finally(() => {
                        this.loading = false;
                    });
                    // doChat(this.chatForm).then((response) => {
                    //     console.log(response);
                    //     let result = response.MobileBody.replay;
                    //     const replyMessage = {
                    //         id: Date.now(),
                    //         content: result,
                    //         time: this.getCurrentDate(),
                    //         isAutoReply: true
                    //     };
                    //     this.messages.push(replyMessage);
                    //     this.scrollToBottom(); //
                    // }).finally(() => {
                    //     this.loading = false;
                    // });

                    // setTimeout(() => {
                    //     const replyMessage = {
                    //         id: Date.now(),
                    //         content: '自动回复：你好，有什么可以帮助你的？' + this.getCurrentDate(),
                    //         time: new Date().toLocaleTimeString(),
                    //         isAutoReply: true
                    //     };
                    //     this.messages.push(replyMessage);//尾部
                    //     this.messages.unshift(replyMessage);//头部
                    //     this.scrollToBottom(); //
                    // }, 1000); // 延迟1秒模拟自动回复
                }
            },
            // startStreaming() {
            //     const requestData = {
            //         classId: "1",
            //         content: "FDA是什么",
            //     };
            //     fetch("http://api.gxpcode.com/assistant/chatConsultation", {
            //         method: "POST",
            //         headers: {
            //             "Content-Type": "application/json",
            //             "X-Gxpcode-Access-Token":localStorage.getItem('token')
            //         },
            //         body: JSON.stringify(requestData)
            //     }).then(response => response.body)
            //         .then(stream => {
            //             const reader = stream.getReader();
            //             const processStream = () => {
            //
            //                 reader.read().then(({ done, value }) => {
            //                     if (done) {
            //                         return;
            //                     }
            //                     const bytes = new Uint8Array(value);
            //                     const decoder = new TextDecoder();
            //                     const str = decoder.decode(bytes).replace(/\n/g, "");
            //                     console.log(str);
            //                     this.streamingData.push(str);
            //                     this.strData = this.streamingData.join("");
            //                     processStream();
            //                 });
            //             };
            //             processStream();
            //         }).finally(() => {
            //             this.loading = false;
            //         });
            // },
            scrollToBottom() {
                this.$nextTick(() => {
                    const container = this.$refs.messageContainer;
                    container.scrollTop = container.scrollHeight;
                });
            },
            showScrollbarFun() {
                this.showScrollbar = true;
                this.divStyle.overflow = 'auto';
            },
            hideScrollbar() {
                this.showScrollbar = false;
                this.divStyle.overflow = 'hidden';
            },
            getCurrentDate() {
                // 获取当前时间
                let currentDate = new Date();
                // 格式化时间
                let year = currentDate.getFullYear();
                let month = String(currentDate.getMonth() + 1).padStart(2, '0');
                let day = String(currentDate.getDate()).padStart(2, '0');
                let hour = String(currentDate.getHours()).padStart(2, '0');
                let minute = String(currentDate.getMinutes()).padStart(2, '0');
                let second = String(currentDate.getSeconds()).padStart(2, '0');
                // 拼接时间字符串
                let formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
                return formattedDate;
            },
            showContactModal() {
                this.contactModalVisible = true;
                this.getContactUsUrl();
            },

            getContactUsUrl() {
                let params = {
                }
                getContactUsUrl(params).then((response) => {
                    this.srcImg = response.MobileBody.sysContactUs
                }).finally(() => {

                });
            },

            closeContactModal() {
                this.contactModalVisible = false;
            }
        }
    };
</script>

<!-- <style scoped>

    .el-container {
        display: flex;
        height: 95vh;
    }

    .el-aside {
        overflow: hidden;
        flex: 0 0 80%; /* 设置左侧区域的宽度 */
        height: 100%;
    }

    .el-main {
        flex: 1; /* 设置右侧区域的宽度自适应 */
    }
    @media (max-width: 768px) {
        .el-aside {
            flex: 0 0 100%;
        }

        .el-main {
            flex: 0;
            display: none;
        }
    }
    .chat-container {
        flex-direction: column;
        height: 80%;
        width: 95%;
    }

    .chat-messages {
        height: 100%;
        padding: 10px;
        width: 100%;
        overflow: auto;
    }

    .message {
        margin-bottom: 10px;
        text-align: right;
    }

    .messages {
        margin-bottom: 10px;
        text-align: left;
    }

    /deep/ .el-collapse-item__header{
        height: 22px !important;
        line-height: 22px  !important;
        border-bottom:none;
    }

    /deep/.el-icon-arrow-right:before{
        display: none !important;
    }

    .message-content {
        font-size: 14px;
    }

    .message-content span {
        background-color: #adceff;
        padding: 5px;
        border-radius: 10px;
        width: auto;
        line-height: 20px;
    }
    .messages {
      margin-bottom: 10px;
      text-align: left;
    }
    .message-time {
        padding-top: 5px;
        font-size: 12px;
        color: #999;
    }
    .auto-reply-message {
        text-align: left;
    }

    .auto-reply {
        background-color: #e4e4e4 !important;
        text-align: left;
        border-radius: 10px;
    }

    .chat-input {
        position: fixed;
        bottom: 0;
        left: 10;
        width: 75%;
        display: flex;
        align-items: center;
        padding: 10px;
    }
    .chat-title {
        text-align: center;
        top: 0;
        left: 0;
        width: 95%;
        background-color: #FFF;
        padding: 10px;
        z-index: 999;
    }
    div::-webkit-scrollbar {
        width: 2px;
        background-color: #f5f5f0;
    }

    div::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 2px;
    }

    div::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
    .trade,
    .devia,
    .other {
        flex-basis: calc(23.33% - 30px);
        height: 68px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .trade {
        /* width: 30%; */
        background: rgba(47, 105, 255, 0.1);
    }
    .devia {
        /* width: 30%; */
        background: rgba(41, 197, 238, 0.12);
    }
    .other {
        /* width: 30%; */
        background: rgba(255, 121, 68, 0.1);
    }

    .trade-image,
    .devia-image,
    .other-image {
        width: 50px;
        height: 30px;
        margin-bottom: 10px;
    }

    .contact-us {
        display: flex;
        justify-content: flex-end;
    }
    .qr-code-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px; /* 设置容器的高度 */
    }
    .qr-code-dialog {
        justify-content: center;
        align-items: center;
        width: 100%; /* 设置容器的高度 */
        height: 100%;
    }
    .qr-code-image {
        max-width: 100%;
        max-height: 20%;
    }
</style> -->

<style scoped>
    /deep/.el-collapse-item__header{
            height: 22px !important;
            line-height: 22px  !important;
            border-bottom:none;
        }

    /deep/ .el-icon-arrow-right:before{
        display: none !important;
    }
    div::-webkit-scrollbar {
        width: 2px;
        background-color: #f5f5f0;
    }

    div::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 2px;
    }

    div::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    .trade,
    .devia,
    .other {
        /* flex-basis: calc(23.33% - 30px); */
        height: 68px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
    }
    .trade {
        background: rgba(47, 105, 255, 0.1);
    }
    .devia {
        background: rgba(41, 197, 238, 0.12);
    }
    .other {
        background: rgba(255, 121, 68, 0.1);
    }

    .trade-image,
    .devia-image,
    .other-image {
        width: 30px;
        height: 15px;
        margin-bottom: 10px;
    }

    .contact-us {
        display: flex;
        justify-content: center;
    }
    .qr-code-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px; /* 设置容器的高度 */
    }

    .qr-code-div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   

  }
  .qr-code-image{
      width: 90%;
    }
   
</style>

<style scoped lang="scss">

.chat {
    position: relative;
    // .chat-input {
    //     // width: 80%;
    //     width: 83%;
    //     position: absolute;
    //     bottom: 0;
    //     left: -10px;
    //     display: flex;
    //     align-items: center;
    //     padding: 10px;
    // }
 .chat-bottom{
    width: 80%;
    position: absolute;
    
    bottom: 0;
    padding: 10px;
    background: #FFF;
    border-top: 1px solid #ccc;
    .chat-bottom-problem{
    //   overflow-x: scroll;
        .chat-bottom-title{
            margin-bottom:10px;
            height: 30px;
            line-height: 30px;
            .chat-bottom-right{
                display: flex;
                justify-content: flex-end;
            }
          
        }
    }
    .chat-bottom-button{
        display: flex;
        align-items: center;
        margin-top:10px;
    }
    // .{

    // }
 }
    
 .chat-left{
    width: 80%;
    height: calc(100vh - 80px);
    overflow: hidden;
    .chat-title {
        text-align: center;
        top: 0;
        left: 0;
        background-color: #FFF;
        padding: 10px;
        z-index: 999;
        font-size: 12px;
    
    }
    .chat-container {
        flex-direction: column;
        // height: 480px;
        height: 80%!important;
        padding-bottom: 100px;
         .chat-messages {
            height: 100%;
            // padding: 10px;
            width: 100%;
            overflow: auto;
            .message {
                margin-bottom: 10px;
                text-align: right;
            }

            .messages {
                margin-bottom: 10px;
                text-align: left;
            }
            .auto-reply-message {
                text-align: left;
            }

            .auto-reply {
                background-color: #e4e4e4 !important;
                text-align: left;
                border-radius: 10px;
            }

            .message-time {
                padding-top: 5px;
                font-size: 12px;
                color: #999;
            }

            .message-content {
                font-size: 14px;
                span{
                    background-color: #adceff;
                    padding: 5px;
                    border-radius: 10px;
                    width: auto;
                    line-height: 20px;
                }
            }
        }
    }

   
 }

 .chat-right{
    width: 20%;
    display: block;
    padding: 8px;
    overflow: auto;
    overflow-x: hidden;
    height: 520px;
    background: #FFF;
    .chat-right-header{
        font-size: 12px;
        color: #ee0a24;
        .chat-right-title{
            text-align: center;
        }

        
    }
    .chat-right-contant{
        width: 100%;
        height: 500px;
        overflow: auto;
        overflow-x: hidden;
        font-size: 12px!important;
        .flex-warp-item {
          width:100%;
          height: 100px;
          display: flex;
          .flex-warp-item-box {
            margin: auto;
            text-align: center;
            display: flex;
            border-radius: 5px;
            background: #f5f5ff;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              background: var(--prev-color-primary-light-8);
              transition: all 0.3s ease;
            }
          }
          @for $i from 0 through 8 {
            .app-animation#{$i} {
              opacity: 0;
              animation-name: error-num;
              animation-duration: 0.5s;
              animation-fill-mode: forwards;
              animation-delay: calc($i/10) + s;
            }
          }
        }
    }
 }

 
 

 
}

.login-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 20px;
  user-select: none;
}

</style>



