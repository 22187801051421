<template>
  <div class="app-container layout-pd">
    <el-row :gutter="15" class="app-card-one">
      <el-col :xs="24">
        <h2>制药专业术语翻译助手</h2>
        <div class="flex-warp mt10">
          <!-- 左侧部分 -->
          <div class="app-card-one-left" :class="!isMobile ? 'app-left' : ''">
            <h2 class="card-title">输入区域</h2>
            <el-form
              @submit.prevent="submitForm"
              :rules="rules"
              class="card-form"
            >
              <el-select
                v-model="prompt.language"
                @change="toggleInputFields"
                size="small"
              >
                <el-option value="en" label="英文到中文"></el-option>
                <el-option value="zh" label="中文到英文"></el-option>
                <el-option value="translate" label="长文本中英互译"></el-option>
              </el-select>
              <el-input
                class="mt10"
                v-model="prompt.word"
                type="textarea"
                rows="8"
                prop="word"
                placeholder="需要解释或翻译的词语/句子填写到这里，如果翻译结果感觉不行，可以再点击翻译按钮一次，会出现可能不一样的结果"
              ></el-input>
              <el-input
                class="mt10"
                v-if="prompt.language !== 'translate'"
                v-model="prompt.context"
                type="text"
                placeholder="在这里输入这个被翻译的词语/句子是在什么剂型或者业务模块下使用的"
              />
              <el-input
                class="mt10"
                v-if="prompt.language !== 'translate'"
                v-model="prompt.scenario"
                type="text"
                placeholder="在这里输入这个被翻译的词语/句子在什么具体业务场景中使用的"
              />
              <el-input
                class="mt10"
                v-if="prompt.language === 'zh'"
                v-model="prompt.explanationWord"
                type="text"
                placeholder="在这里输入这个中文词语/句子的含义.注意！这里是必须输入的。"
              />
            </el-form>
          </div>
          <!-- 右侧部分 -->
          <div class="app-card-one-right" :class="!isMobile ? 'app-right' : ''">
            <div class="output-header">
              <h2 class="card-title">解释与翻译</h2>
              <el-button
                id="copyButton"
                :disabled="copyButtonDisabled"
                @click="copyTranslation"
                size="mini"
              >
                复制
              </el-button>
            </div>
            <div ref="messageContainer" @mouseenter="showScrollbarFun" @mouseleave="hideScrollbar">
            <el-input
              readonly
              id="explanationArea"
              type="textarea"
              class="mt10"
              rows="14"
              v-model="explanation"
              placeholder="这里会出现解释结果，请等候"
            ></el-input></div>
          </div>

          <!-- 广告 -->
          <div class="chat-right" v-if="!isMobile">
            <div class="chat-right-header">
              <div class="chat-right-title">广告位招租</div>
            </div>
            <div class="chat-right-contant">
              <div class="flex-warp-item" v-for="(v, k) in homeThree" :key="k">
                <div class="flex-warp-item-box" :class="`app-animation${k}`">
                  <div class="flex-margin">
                    <span class="pl5">{{ v.label }}</span>
                    <div class="mt10">{{ v.value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="app-card-one-footer mt10"
          v-loading="loading"
          :class="!isMobile ? 'app-footer' : ''"
        >
          <el-button
            class="footer-button"
            type="primary"
            @click="submitForm"
            id="submitButton"
          >
            翻译
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="15" class="app-card-two" v-if="isMobile">
      <el-col :xs="24">
        <div class="app-card-item">
          <div class="app-card-item-title">广告招租</div>
          <div class="app-monitor">
            <div class="flex-warp">
              <div class="flex-warp-item" v-for="(v, k) in homeThree" :key="k">
                <div class="flex-warp-item-box" :class="`app-animation${k}`">
                  <div class="flex-margin">
                    <span class="pl5">{{ v.label }}</span>
                    <div class="mt10">{{ v.value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { translate } from "@/api/order";
import {getRecordNodesList} from "@/api";
export default {
  data() {
    return {
      homeThree: [
        {},
        {}
      ],

      isMobile: false,
      prompt: {
        language: "en",
        word: null,
        context: null,
        scenario: null,
        explanationWord: "",
      },
      divStyle: {
        overflow: 'hidden'
      },
      explanation: "",
      submitButtonColor: "#1a73e8",
      showSpinner: false,
      copyButtonDisabled: true,
      loading: false,
      rules: {
        word: [
          { required: true, message: "词语或者句子不能为空", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    if (this.isMobiles()) {
      console.log(1111111, "移动端");
      this.isMobile = true;
    } else {
      console.log(2222222, "pc端");
      this.isMobile = false;
    }
  },

  methods: {
    toggleInputFields() {
      if (this.prompt.language === "translate") {
        this.prompt.context = "";
        this.prompt.scenario = "";
        this.prompt.explanationWord = "";
      }
    },
    submitForm() {
      // if (this.prompt.word == null) {
      //   alert("词语或句子不能为空");
      //   return;
      // }
      // this.loading = true;
      // translate(this.prompt).then((response) => {
      //   this.explanation = response.MobileBody.result;
      //   console.log(response);
      //   this.loading = false;
      //   this.copyButtonDisabled = false;
      // });

      this.streamingData = [];
      if (this.prompt.word !== '') {
        this.loading = true;
        this.scrollToBottom(); //
        // this.prompt.word = '';
        let start = true;
        fetch("http://api.gxpcode.com/openai/promptAiFlow", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Gxpcode-Access-Token":localStorage.getItem('token')
          },
          body: JSON.stringify(this.prompt)
        }).then(response => response.body)
            .then(stream => {
              const reader = stream.getReader();
              const processStream = () => {

                reader.read().then(({ done, value }) => {
                  if (done) {
                    return;
                  }
                  const bytes = new Uint8Array(value);
                  const decoder = new TextDecoder();
                  // const str = decoder.decode(bytes).replace(/\n/g, "");
                  const str = decoder.decode(bytes);
                  if(str=='-7'){
                    location.href = '/Login';
                  }

                  this.streamingData.push(str);
                  this.strData = this.streamingData.join("");
                  if (start) {
                    this.explanation = this.strData;
                    start = false;
                  } else {
                    this.strData = this.strData.replace("根据提供的背景信息，", "");
                    this.strData = this.strData.replace("根据提供的上下文信息，", "");
                    this.explanation = this.strData;
                  }

                  this.scrollToBottom(); //
                  processStream();
                });
              };
              processStream();
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    showScrollbarFun() {
      this.showScrollbar = true;
      this.divStyle.overflow = 'auto';
    },
    hideScrollbar() {
      this.showScrollbar = false;
      this.divStyle.overflow = 'hidden';
    },
    copyTranslation() {
      // 复制解释结果到剪贴板
      const explanationArea = document.getElementById("explanationArea");
      explanationArea.select();
      document.execCommand("copy");
      alert("翻译结果已复制");
    },

    isMobiles() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style scoped lang="scss">
$homeNavLengh: 8;
div::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f0;
}

div::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
}

div::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.app-container {
  .app-card-one {
    // width: 100%;
    height: 100%;
    background: var(--prev-bg-white);
    overflow: hidden;
    h2 {
      text-align: center;
      margin-top: 10px;
      color: #1a73e8;
    }

    .app-left {
      width: 40% !important;
    }
    .app-right {
      width: 40% !important;
    }
    .app-card-one-left {
      box-sizing: border-box;
      width: 50%;
      // margin-left: 10px;
      padding: 10px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      .card-title {
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        color: black;
        margin: 10px auto;
      }
      .card-form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .app-card-one-right {
      box-sizing: border-box;
      width: 50%;
      padding: 10px;
      background-color: #ddd;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      .output-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-title {
          font-size: 15px;
          font-weight: bold;
          color: black;
        }
      }
    }

    .chat-right {
      width: 20%;
      .chat-right-header {
        font-size: 12px;
        color: #ee0a24;
        .chat-right-title {
          text-align: center;
        }
      }
      .chat-right-contant {
        width: 100%;
        height: 400px;
        overflow: auto;
        overflow-x: hidden;
        font-size: 12px !important;
        .flex-warp-item {
          width: 100%;
          height: 100px;
          display: flex;
          .flex-warp-item-box {
            margin: auto;
            text-align: center;
            display: flex;
            border-radius: 5px;
            background: #f5f5ff;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              background: var(--prev-color-primary-light-8);
              transition: all 0.3s ease;
            }
          }
          @for $i from 0 through 8 {
            .app-animation#{$i} {
              opacity: 0;
              animation-name: error-num;
              animation-duration: 0.5s;
              animation-fill-mode: forwards;
              animation-delay: calc($i/10) + s;
            }
          }
        }
      }
    }

    .app-card-one-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .footer-button {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }

    .app-footer {
      width: 80% !important;
    }
  }
  .app-card-two {
    margin-top: 10px;

    .app-card-item {
      width: 100%;
      height: 130px;
      border-radius: 4px;
      transition: all ease 0.3s;
      padding: 20px;
      overflow: hidden;
      background: var(--prev-bg-white);
      border: 1px solid #f1f2f3;
      &:hover {
        box-shadow: 0 2px 12px #0000001a;
        transition: all ease 0.3s;
      }
      &-icon {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        flex-shrink: 1;
        i {
          color: #7a7a7a;
        }
      }
      &-title {
        font-size: 15px;
        font-weight: bold;
        height: 30px;
      }
    }
  }
  .app-card-two {
    .app-card-item {
      // height: 400px;
      width: 100%;
      height: 100%;
      // overflow-y: scroll;
      .app-monitor {
        height: 100%;
        .flex-warp-item {
          width: 33.3%;
          height: 111px;
          display: flex;
          .flex-warp-item-box {
            margin: auto;
            text-align: center;
            display: flex;
            border-radius: 5px;
            background: #f5f5ff;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              background: var(--prev-color-primary-light-8);
              transition: all 0.3s ease;
            }
          }
          @for $i from 0 through $homeNavLengh {
            .app-animation#{$i} {
              opacity: 0;
              animation-name: error-num;
              animation-duration: 0.5s;
              animation-fill-mode: forwards;
              animation-delay: calc($i/10) + s;
            }
          }
        }
      }
    }
  }
}
</style>
