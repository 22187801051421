<template>
  <div style="width: 100%; background: #f1f1f1; height: 100vh;">
    <el-card class="title-card">
      <div slot="header" class="clearfix">
        <el-avatar class="title-image" :src="user.headImg"></el-avatar>
        <span class="name-text">毛不易</span>
        <el-button
          class="exit"
          icon="el-icon-switch-button"
          @click="exitClick"
          type="primary"
          plain
          >退出</el-button
        >
      </div>
      <div class="information">
        <el-avatar class="content-image" :src="user.headImg"></el-avatar>
        <div class="infor-text">
          <p>
            <span class="count-text">您好</span>
          </p>
          <p>
            <span class="count-text">账号：{{ user.mobileNo }}</span>
          </p>
        </div>
        <el-row class="button-content">
          <el-col><el-button icon="el-icon-user" circle></el-button></el-col>
          <span class="button-font">我的账户</span>
        </el-row>
        <el-row class="button-content">
          <el-col
            ><el-button icon="el-icon-message" circle></el-button>
          </el-col>
          <span class="button-font">帮助中心</span>
        </el-row>
        <el-row class="button-content">
          <el-col
            ><el-button icon="el-icon-document" circle></el-button
          ></el-col>
          <span class="button-font">意见反馈</span>
        </el-row>
        <el-row class="button-content">
          <el-col><el-button class="button-in" icon="el-icon-setting" circle></el-button></el-col>
          <span class="button-font-in">账户设置</span>
        </el-row>
      </div>
    </el-card>

    <div class="content">
      <div class="content-setting">
        <span class="text">头像</span>
        <div class="content-setting1">
          <el-avatar
            class="avatar"
            src="https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg"
          ></el-avatar>
          <el-button class="setting-button" type="primary"
            ><i class="el-icon-upload el-icon--right"></i>上传</el-button
          >
        </div>
      </div>
      <el-divider></el-divider>
      <div class="content-setting">
        <span class="text">昵称</span>
        <div class="content-setting1">
          <el-input v-model="input" placeholder="请输入内容"></el-input>
          <el-button class="setting-button" type="primary" icon="el-icon-edit"
            >修改</el-button
          >
        </div>
      </div>
      <el-divider></el-divider>
      <div class="content-setting">
        <span class="text">密码</span>
        <el-button class="setting-button" type="primary" icon="el-icon-edit"
          >修改</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { loginOut, userInfo } from "@/api/index";
import { wxPayPre, queryOrder, getOrderList } from "@/api/order";
import QRCode from "qrcode";

export default {
  data() {
    return {
      urls: [
        "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg",
      ],
      user: {
        money: null,
        mobileNo: null,
        headImg: null,
      },
      input:'毛不易',
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      userInfo().then((response) => {
        this.user = response.MobileBody;
        if (this.user.headImg == null) {
          this.user.headImg =
            "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg";
        }
      });
      getOrderList(this.queryParams).then((response) => {
        this.moneyData = response.MobileBody.orderList;
      });
    },
  },
};
</script>

<style scoped>
.title-card {
  width: 100%;
  background-color: #fafafa;
}

.box-card {
  margin: 20px;
  width: 97%;
}

.exit {
  padding: 3px 0;
  width: 80px;
  height: 30px;
  border-radius: 5px;
}

.clearfix {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.title-image {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
}

.name-text {
  font-size: 14px;
  margin-right: 30px;
}

.content-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
}

.button-content {
  margin-right: 0;
}

.button-font {
  font-size: 10px;
}

.button-in {
  color: #1f75e8;
}

.button-font-in {
  font-size: 10px;
  color: #1f75e8;
}

.information {
  height: 40px;
  margin: 5px;
  display: flex;
  padding: 15px;
}

.infor-text {
  width: 65%;
  margin: -10px 0 0 5px;
  font-size: 12px;
}

.count-text {
  margin-bottom: -10px;
}

.content {
  margin: 20px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
}

.content-setting {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.content-setting1 {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.text {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 30px;
}

.avatar {
  justify-content: space-between;
  margin-right: 25px;
}

.el-input {
  margin-right: 25px;
  width: 200px;
}

.setting-button {
  padding: 3px 0;
  width: 80px;
  height: 30px;
  border-radius: 5px;
}
</style>
