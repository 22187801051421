<template>
  <div class="personal layout-pd">
    <el-row>
      <!-- 个人信息 -->
      <el-col :xs="24" :sm="24">
        <el-card shadow="hover">
          <div slot="header" class="personal-header">
            <span>个人信息</span>
            <div class="personal-clearfix">
              <el-avatar size="small" :src="user.headImg"></el-avatar>
              <span class="personal-left">{{ user.nickTame }}</span>
              <el-button
                class="personal-left"
                icon="el-icon-switch-button"
                @click="exitClick"
                type="primary"
                size="small"
                plain
                >退出</el-button
              >
            </div>
          </div>
          <div class="personal-user">
            <div class="personal-user-left">
              <el-upload
                class="h100 personal-user-left-upload"
                action="https://jsonplaceholder.typicode.com/posts/"
                multiple
                :limit="1"
              >
                <img :src="user.headImg" />
              </el-upload>
            </div>
            <div class="personal-user-right">
              <el-row>
                <el-col :span="24" class="personal-title mb18">{{
                  user.nickTame
                }}</el-col>
                <el-col :span="24">
                  <el-row>
                    <el-col :xs="24" :sm="16" class="personal-item mb6">
                      <div class="personal-item-label one-text-overflow">
                        您好
                        <span
                          class="count-text"
                          v-if="user.nickName !== null && user.nickName !== ''"
                          >,{{ user.nickName }}</span
                        >
                        <span
                          class="count-text"
                          v-if="user.mobileNo !== null && user.mobileNo !== ''"
                          >,{{ user.mobileNo }}</span
                        >
                        <el-button
                          v-if="
                            (user.mobileNo === null || user.mobileNo === '') &&
                            user.type === 'weixin'
                          "
                          class="button-in"
                          @click="bindMobile"
                          circle
                          >绑定手机号</el-button
                        >
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="8" class="personal-item mb6">
                      <div class="personal-item-label one-text-overflow">
                        <el-button size="small" @click="copyLink"
                          >复制邀请链接</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="24">
                  <el-row>
                    <el-col :xs="24" :sm="16" class="personal-item mb6">
                      <div class="personal-item-value one-text-overflow">
                        <span class="count-vip" v-if="user.vipType === 0">
                          普通用户</span
                        >
                        <span class="count-vip" v-if="user.vipType === 1">
                          月度会员</span
                        >
                        <span class="count-vip" v-if="user.vipType === 2">
                          年度会员</span
                        >
                       
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="8" class="personal-item mb6">
                      <div class="personal-item-label one-text-overflow">
                        <div class="button-font-invite">
                          (邀请使用微信登录成功后有奖)
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>

                <el-col :span="24">
                  <el-row>
                    <el-col :xs="24" :sm="16" class="personal-item mb6">
                      <div class="personal-item-value one-text-overflow">
                       
                        <span v-if="user.vipType !== 0">
                          会员有效期：{{ user.vipExpirationTime }}
                         
                          </span
                        >
                      </div>
                    </el-col>
                   
                  </el-row>
                </el-col>


              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>

      <!-- 余额展示 -->
      <el-col :span="24" class="personal-balance mt15">
        <div class="personal-balance-left">
          <span>余额:{{ user.money }}</span>
        </div>
        <div class="personal-balance-right">
          <el-button plain class="money" @click="showDialogVip">
            <span class="button-text">购买会员</span>
          </el-button>
        </div>
      </el-col>

      <!-- 记录信息 -->
      <el-col :span="24">
        <el-card shadow="hover" class="mt15 personal-edit" header="我的记录">
          <el-tabs v-model="activeName">
            <el-tab-pane label="账单" name="first">
              <el-table :data="countData" style="width: 100%" row-key="path">
                <el-table-column
                  prop="createTime"
                  label="时间"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column label="金额" show-overflow-tooltip>
                  <template #default="scope">
                    ￥{{ scope.row.money }}
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="充值" name="second">
              <el-table :data="moneyData" style="width: 100%" row-key="path">
                <el-table-column
                  prop="orderId"
                  label="订单号"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="下单时间"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column label="金额" show-overflow-tooltip>
                  <template slot-scope="scope">
                    ￥{{ scope.row.realMoney }}
                  </template>
                </el-table-column>
                <el-table-column label="状态" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ getStatusDesc(scope.row.status) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="payTime"
                  label="支付时间"
                  show-overflow-tooltip
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
    <!-- 购买会员 -->
    <el-dialog :visible.sync="dialogVisibleVip" title="购买会员">
      <div>
        <el-button
          key="month"
          :type="selectedVip === 1 ? 'primary' : ''"
          @click="selectVip(1)"
          >月会员(￥20)</el-button
        >
        <el-button
          key="year"
          :type="selectedVip === 2 ? 'primary' : ''"
          @click="selectVip(2)"
          >年会员(￥200)</el-button
        >
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelVip" size="default">取 消</el-button>
          <el-button type="primary" @click="confirmAmountVip" size="default"
            >购买</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 绑定手机号 -->
    <el-dialog :visible.sync="dialogVisibleBind" title="绑定手机号">
      <el-form
        ref="bindForm"
        :model="bindForm"
        :rules="bindRules"
        label-width="80px"
      >
        <el-form-item label="手机号" prop="mobileNo">
          <el-input v-model="bindForm.mobileNo"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="verificationCode">
          <div class="el-row" span="24">
            <div class="el-col el-col-14">
              <el-input
                type="text"
                maxlength="6"
                v-model="bindForm.verificationCode"
                clearable
                autocomplete="off"
              ></el-input>
            </div>
            <div class="el-col el-col-10">
              <div class="login-code">
                <el-button @click.prevent="sendCode" v-if="!countdown">
                  发送验证码</el-button
                >
                <span v-else class="countdown">{{ countdown }}s</span>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="bindMobileCancel">取 消</el-button>
          <el-button type="primary" @click.prevent="submitForm">绑定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 充值 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="充值"
    >
      <div class="amount-buttons">
        <!-- <el-button
          v-for="amount in amounts"
          :key="amount"
          :type="selectedAmount === amount ? 'primary' : ''"
          @click="selectAmount(amount)"
          >{{ amount }}元</el-button
        > -->
        <el-row :gutter="15" >
						<el-col :sm="6" v-for="(amount, k) in amounts" :key="k" style="margin-top:10px">
							<el-button
                :type="selectedAmount === amount ? 'primary' : ''"
                @click="selectAmount(amount)"
                >{{ amount }}元</el-button>
						</el-col>
				 </el-row>
      </div>
      <div class="custom-amount">
        <el-input
          style="margin-left: 0px; width: 98%"
          type="number"
          :min="10"
          v-model.number="customAmount"
          placeholder="其他金额，请取整数"
        ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="confirmAmount">去充值</el-button>
        </span>
      </template>
    </el-dialog>
     <!-- 微信扫码支付 -->
    <el-dialog
          title="微信扫码支付"
          :visible.sync="QrDialogVisible"
        
        >
          <div style="text-align: center">
            <el-row class="button-invite" style="text-align: center;">
              <el-col>
                <img :src="qrcodeUrl" alt="二维码" />
              </el-col>
              <span class="button-font-invite">用微信扫码支付{{ selectedAmount }}元</span>
            </el-row>
          </div>
        </el-dialog>
  </div>
</template>

<script>
import { loginOut, userInfo, getMobileCode, bindMobile } from "@/api/index";
import {
  wxPayPre,
  queryOrder,
  getOrderList,
  getBalanceList,
} from "@/api/order";
import QRCode from "qrcode";
import { VTooltip } from "v-tooltip";
import ClipboardJS from "clipboard";
import { Message } from "element-ui";

export default {
  //   name: "Home",
  directives: {
    tooltip: VTooltip,
  },
  data() {
    return {
      state: {
        newsInfoList: [],
        recommendList: [],
        personalForm: {
          name: "",
          email: "",
          autograph: "",
          occupation: "",
          phone: "",
          sex: "",
        },
      },
      paymentInterval: null, // 轮询定时器
      timeoutInterval: null, // 超时定时器
      dialogVisible: false,
      dialogVisibleVip: false,
      QrDialogVisible: false,
      dialogVisibleBind: false,
      qrcodeUrl: null,
      selectedAmount: null,
      selectedVip: null,
      vipItems: ["月会员(￥38)", "年会员(￥200)"],
      amounts: [10, 20, 50, 100],
      customAmount: null,
      activeName: "first",
      input: "",
      urls: [
        "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg",
      ],
      user: {
        money: null,
        mobileNo: null,
        headImg: null,
        nickName: null,
        vipType: null,
        vipExpirationTime: null,
        invitationCode: null,
        type: null,
      },
      form: {
        money: undefined,
        orderId: undefined,
        rechargeType: undefined,
      },
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      queryBalanceParams: {
        page: 1,
        pageSize: 10,
        type: 2,
      },
      countData: [],
      moneyData: [],
      bindForm: {
        mobileNo: undefined,
        verificationCode: "",
      },
      bindRules: {
        mobileNo: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      countdown: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    submitForm() {
      this.$refs.bindForm.validate((valid) => {
        if (valid) {
          bindMobile(this.bindForm).then((response) => {
            Message({
              message: "绑定成功",
              type: "succcess",
              duration: 2 * 1000,
            });
            this.$router.go(0);
          });
        } else {
          return false;
        }
      });
    },
    sendCode() {
      if (this.bindForm.mobileNo !== undefined) {
        getMobileCode(this.bindForm).then((response) => {
          console.log(response.data);
        });
        // 发送短信验证码逻辑
        // 倒计时开始
        this.countdown = 60;
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      }
    },
    init() {
      userInfo().then((response) => {
        this.user = response.MobileBody;
        console.log(response.MobileBody);
        if (this.user.headImg == null) {
          this.user.headImg =
            "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg";
        }
      });
      getOrderList(this.queryParams).then((response) => {
        this.moneyData = response.MobileBody.orderList;
      });
      getBalanceList(this.queryBalanceParams).then((response) => {
        this.countData = response.MobileBody.balanceList;
      });
    },
    copyLink() {
      const host =
        window.location.host +
        "/Login?invitationCode=" +
        this.user.invitationCode;
      const clipboard = new ClipboardJS(".button-in", {
        text: () => host,
      });

      clipboard.on("success", () => {
        this.$message.success("链接已复制");
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        this.$message.error("复制链接失败");
        clipboard.destroy();
      });

      clipboard.onClick(event);
    },
    generateQRCode(url) {
      QRCode.toDataURL(url)
        .then((qrcodeUrl) => {
          this.qrcodeUrl = qrcodeUrl;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    startPaymentPolling() {
      this.paymentInterval = setInterval(() => {
        this.checkPaymentStatus();
      }, 3000); // 每3秒轮询一次支付状态，根据实际情况调整间隔时间
      const timeoutDuration = 60000; // 设置超时时间为60秒，根据实际情况调整
      this.timeoutInterval = setTimeout(() => {
        clearInterval(this.paymentInterval); // 停止轮询
        // 处理超时逻辑，例如显示超时提示信息
      }, timeoutDuration);
    },

    checkPaymentStatus() {
      queryOrder(this.form)
        .then((response) => {
          const status = response.MobileBody.status;
          if (status === 1) {
            this.$message.success("支付成功");
            this.handlePaymentSuccess();
          } else if (status === 2) {
            this.$message.error("已取消支付");
            this.handlePaymentSuccess();
          } else {
            // 支付失败或其他状态，根据实际情况处理
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handlePaymentSuccess() {
      clearTimeout(this.timeoutInterval); // 清除超时定时器
      clearInterval(this.paymentInterval); // 停止轮询
      this.init();
      this.QrDialogVisible = false; // 关闭弹窗
      this.form.orderId = null;
      this.selectedAmount = null;
      this.customAmount = null;
    },
    handleTimeout() {
      clearInterval(this.paymentInterval); // 停止轮询
      // 处理超时逻辑，例如显示超时提示信息
    },
    //退出
    exitClick() {
      loginOut().then((response) => {
        this.$router.push({ path: "/Login" });
      });
    },
    bindMobile() {
      this.dialogVisibleBind = true;
    },
    bindMobileCancel() {
      this.dialogVisibleBind = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    showDialogVip() {
      this.dialogVisibleVip = true;
    },
    cancelVip() {
      this.dialogVisibleVip = false;
    },
    cancel() {
      this.dialogVisible = false;
      this.selectedAmount = null;
      this.customAmount = null;
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.customAmount = amount;
    },
    selectVip(type) {
      this.selectedVip = type;
      if (type === 1) {
        this.selectedAmount = 20;
      } else {
        this.selectedAmount = 200;
      }
      this.form.rechargeType = type;
    },
    confirmAmountVip() {
      if (this.selectedAmount != null) {
        this.form.money = this.selectedAmount * 100;
        wxPayPre(this.form).then((response) => {
          this.generateQRCode(response.MobileBody.codeUrl);
          this.dialogVisibleVip = false;
          this.QrDialogVisible = true;
          this.form.orderId = response.MobileBody.orderId;
          this.startPaymentPolling();
        });
      } else {
        this.$message.error("请选择会员类型");
      }
    },
    confirmAmount() {
      if (this.selectedAmount != null || this.customAmount != null) {
        this.form.money = this.customAmount * 100;
        wxPayPre(this.form).then((response) => {
          this.generateQRCode(response.MobileBody.codeUrl);
          this.dialogVisible = false;
          this.QrDialogVisible = true;
          this.form.orderId = response.MobileBody.orderId;
          this.startPaymentPolling();
        });
      } else {
        this.$message.error("请选择一个金额或输入自定义金额");
      }
    },
    getStatusDesc(status) {
      if (status === 0) {
        return "未付款";
      } else if (status === 1) {
        return "已付款";
      } else if (status === 2) {
        return "已取消";
      } else if (status === 3) {
        return "已完成";
      } else if (status === 4) {
        return "已退款";
      } else if (status === 5) {
        return "已删除";
      } else {
        return "";
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutInterval); // 清除超时定时器
    clearInterval(this.paymentInterval); // 组件销毁时停止轮询
  },
};
</script>

<style scoped lang="scss">
.personal {
  .personal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .personal-clearfix {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .personal-left {
        margin-left: 10px;
      }
    }
  }

  .personal-user {
    height: 130px;
    display: flex;
    align-items: center;
    .personal-user-left {
      width: 100px;
      height: 130px;
      border-radius: 3px;
      :deep(.el-upload) {
        height: 100%;
      }
      .personal-user-left-upload {
        img {
          width: 100%;
          height: 100%;
          border-radius: 3px;
        }
        &:hover {
          img {
            animation: logoAnimation 0.3s ease-in-out;
          }
        }
      }
    }
    .personal-user-right {
      flex: 1;
      padding: 0 15px;
      .personal-title {
        font-size: 18px;
      }
      .personal-item {
        display: flex;
        align-items: center;
        font-size: 13px;
        .personal-item-label {
          color: var(--el-text-color-secondary);
          .button-font-invite {
            color: red;
          }
        }
        .personal-item-value {
          .count-vip {
            background-image: linear-gradient(to right, #ff7a45, #febe67);
            font-size: 16px;
          }
        }
      }
    }
  }

  .personal-balance {
    display: flex;
    justify-content: space-between;
    align-self: center;
    height: 60px;
    line-height: 60px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #ff7a45, #febe67);
    .personal-balance-left {
      margin-left: 40px;
      font-size: 12px;
      color: white;
    }
    .personal-balance-right {
      margin-right: 40px;
      .button-text {
        color: #ff7a45;
      }
    }
  }
}

.login-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 20px;
  user-select: none;
}
.amount-buttons{
  margin-bottom: 10px;
}

.button-font-invite {
  font-size: 12px;
  color: red;
}
</style>
