<template>
  
  <div class="market layout-pd">
		<el-row>
      <!-- hearder -->
      <el-col :span="24">
				<el-card shadow="hover" class="mt15 market-header">
          <div class="market-header-title">您好，我们是您身边的制药专业小能手！</div>
          <div class="market-header-button"><el-button type="primary" @click="showContactModal">联系我们</el-button></div>
				</el-card>
			</el-col>
			<!-- 应用中心 -->
			<el-col :span="24">
				<el-card shadow="hover" class="mt15" header="应用中心">
					<el-row :gutter="15" class="market-recommend-row">
						<el-col :sm="8" v-for="(v, k) in recommendList" :key="k" class="market-recommend-col" @click.native="redirectToPage(k)">
							<div class="market-recommend" :style="{ 'background-color': v.bg }">
                <div class="market-recommend-img">
                  <img  :src="v.src" />
                </div>
               
								<div class="market-recommend-auto">
									<div class="market-recommend-msg">{{ v.name}}</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>

    <el-dialog class="qr-code-dialog" title="联系我们" :visible.sync="contactModalVisible" @close="closeContactModal">
      <div class="qr-code-div">
        <img class="qr-code-image" :src="srcImg" alt="二维码图片">
      </div>
     
    </el-dialog>
	</div>
</template>

<script>
  import { wxPayPre, queryOrder, getOrderList } from "@/api/order";
  import { getContactUsUrl, } from "@/api/index";
  import Img001 from "@/assets/img_fanyi@2x.png"
  import Img002 from "@/assets/img_pianchazhushou@2x.png"
  import Img003 from "@/assets/thumb3.png"
  import Img004 from "@/assets/thumb2.png"
  import Img005 from "@/assets/thumb1.png"
  import Img006 from "@/assets/img_qita@2x.png"
  import Img007 from "@/assets/todo.png"
  export default {
    data() {
      return {
        recommendList:[
          {
            name:"翻译助手",
            src:Img001,
            bg:"rgba(47, 105, 255, 0.1)"
          },
          {
            name:"GMP助手",
            src:Img002,
            bg:"rgba(41, 197, 238, 0.12)"
          },
          {
            name:"DI&CSV助手",
            src:Img003,
            bg:"rgba(255, 121, 68, 0.1)"
          },
          {
            name:"GSP助手",
            src:Img004,
            bg:"rgba(47, 105, 255, 0.1)"
          },
          {
            name:"无菌保障助手",
            src:Img005,
            bg:"rgba(41, 197, 238, 0.12)"
          },
          {
            name:"MAH助手",
            src:Img006,
            bg:"rgba(255, 121, 68, 0.1)"
          },

          {
            name:"药物警戒助手",
            src:Img002,
            bg:"rgba(41, 197, 238, 0.12)"
          },
          {
            name:"注册申报助手",
            src:Img007,
            bg:"gainsboro"
          },
          {
            name:"更多专业助手",
            src:Img007,
            bg:"gainsboro"
          },
        ],

        contactModalVisible: false,
        urls: [
          "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg",
        ],
        user: {
          money: null,
          mobileNo: null,
          headImg: null,
        },
        srcImg:"",
      };
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        // userInfo().then((response) => {
        //   this.user = response.MobileBody;
        //   if (this.user.headImg == null) {
        //     this.user.headImg =
        //       "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg";
        //   }
        // });
      },
      redirectToPage(index) {
        // this.$router.push('/Application'); // 跳转到目标页面
        // this.$router.go(0);
        if(index==0){
          this.$router.push('/Application'); // 跳转到目标页面
          this.$router.go(0);
        }else if(index==1||index==2||index==3||index==4||index==5){
          this.$router.push({
            path: '/Chat/'+index,
          });
        }else if(index==6){
          this.$router.push({
            path: '/Chat/'+7,
          });
        }
      },
      redirectToTools(obj) {
        this.$router.push({
          path: '/Chat/'+obj,
        });
      },
    
      showContactModal() {
        this.contactModalVisible = true;
        this.getContactUsUrl();
      },
      closeContactModal() {
        this.contactModalVisible = false;
      },
      getContactUsUrl() {
          let params = {
          }
          getContactUsUrl(params).then((response) => {
              this.srcImg = response.MobileBody.sysContactUs
          }).finally(() => {

          });
      },
    }
  };
</script>
<style scoped lang="scss">
.market {
  .market-header{
    .market-header-title{
      margin: 15px 0 20px 0;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .market-header-button{
      display: flex;
      justify-content: flex-end;
    }

  }
	.market-recommend-row {
		.market-recommend-col {
      margin-top:10px;
			.market-recommend {
				position: relative;
				height: 160px;
				border-radius: 3px;
				overflow: hidden;
				cursor: pointer;
        .market-recommend-img{
          position: absolute;
					left: 50%;
          transform: translate(-50%,-50%);
					top: 54px;
          img{
            width: 75px;
            height: 75px;
          }
        }
				.market-recommend-auto {
					padding: 15px;
					position: absolute;
					left: 50%;
          transform: translate(-50%,-50%);
					top: 70%;
					.market-recommend-msg {
						font-size: 18px;
            font-weight: 500;
            color: #316bff;
					}
				}
			}
		}
	}

  // .qr-code-dialog {
  //   // display: flex;
  //   // justify-content: center;
  //   // align-items: center;
  //   width: 100%; /* 设置容器的高度 */
  //   height: 100%;
  //   margin: 0 auto;
  //   .qr-code-image {
  //     max-width: 100%;
  //     // max-height: 20%;
  //     // position: absolute;
  //     // left: 50%;
  //     // transform: translateX(-50%);
     
    
  //   }
  // }

  .qr-code-div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .qr-code-image{
      width: 90%;
    }

  }
  
}
</style>
