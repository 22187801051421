import http from '../utils/request'


// 充值
export function wxPayPre(data) {
    return http({
        url: '/wxPay/checkout',
        method: 'post',
        data: data
    })
}
// 查询订单详情
export function queryOrder(query) {
    return http({
        url: '/order/getOrderInfoByOrderId',
        method: 'get',
        params: query
    })
}
export function getOrderList(data) {
    return http({
        url: '/order/getOrderList',
        method: 'post',
        data: data
    })
}

export function getBalanceList(data) {
    return http({
        url: '/userBalance/getUserRechargeLog',
        method: 'post',
        data: data
    })
}


// 翻译
export function translate(data) {
    return http({
        url: '/openai/promptAi',
        method: 'post',
        data: data
    })
}
