import request from '@/utils/request'

// 查询列表
export function listPrompt(data) {
  return request({
    url: '/fanYiPrompt/getFanYiPrompt',
    method: 'post',
    data: data
  })
}

// 查询详细
export function getPrompt(data) {
  return request({
    url: '/fanYiPrompt/queryFanYiPrompt',
    method: 'post',
    data: data
  })
}

// 新增
export function addPrompt(data) {
  return request({
    url: '/fanYiPrompt/addFanYiPrompt',
    method: 'post',
    data: data
  })
}

// 修改
export function updatePrompt(data) {
  return request({
    url: '/fanYiPrompt/updateFanYiPrompt',
    method: 'post',
    data: data
  })
}

// 删除
export function delPrompt(data) {
  return request({
    url: '/fanYiPrompt/deleteFanYiPrompt',
    method: 'post',
    data: data
  })
}
// 删除
export function deleteFanYiPromptById(id) {
  return request({
    url: '/fanYiPrompt/deleteFanYiPromptById/'+id,
    method: 'post'
  })
}

// 插入缓存
export function handleInsertRedis() {
  return request({
    url: '/fanYiPrompt/insertFanYiPromptToRedis',
    method: 'post'
  })
}
// 删除缓存
export function deleteFanYiPromptRedis() {
  return request({
    url: '/fanYiPrompt/deleteFanYiPromptRedis',
    method: 'post'
  })
}
