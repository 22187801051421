<template>
  <div style="width: 100%; background: #f1f1f1">
    <el-card class="title-card">
      <div slot="header" class="clearfix">
        <el-avatar class="title-image" :src="user.headImg"></el-avatar>
        <span class="name-text">毛不易</span>
        <el-button
          class="exit"
          icon="el-icon-switch-button"
          @click="exitClick"
          type="primary"
          plain
          >退出</el-button
        >
      </div>
      <div class="information">
        <el-avatar class="content-image" :src="user.headImg"></el-avatar>
        <div class="infor-text">
          <p>
            <span class="count-text">您好</span>
          </p>
          <p>
            <span class="count-text">账号：{{ user.mobileNo }}</span>
          </p>
        </div>
        <el-row class="button-content">
          <el-col><el-button icon="el-icon-user" circle></el-button></el-col>
          <span class="button-font">我的账户</span>
        </el-row>
        <el-row class="button-content">
          <el-col
            ><el-button
              class="button-in"
              icon="el-icon-message"
              circle
            ></el-button>
          </el-col>
          <span class="button-font-in">帮助中心</span>
        </el-row>
        <el-row class="button-content">
          <el-col
            ><el-button icon="el-icon-document" circle></el-button
          ></el-col>
          <span class="button-font">意见反馈</span>
        </el-row>
        <el-row class="button-content">
          <el-col><el-button icon="el-icon-setting" circle></el-button></el-col>
          <span class="button-font">账户设置</span>
        </el-row>
      </div>
    </el-card>

    <div class="content">
      <div class="content-header">
        <img class="content-icon" src="@/assets/icon_weda_1@2x.png" />
        <span class="content-title">翻译助手怎么使用效果最好</span>
      </div>
      <div class="content-answer1">
        <div style="margin: 20px">
          <p>
            <span class="answer-text">这里是回答，这里是回答。</span>
          </p>
          <p>
            <span class="answer-text"
              >在药品生产与研发领域的药物语境中，“Declaration and
              Registration”指的是药品的申报和注册过程。这是指制药公司在准备上市新的药物或药品之前需要进行的一系列程序。在申报阶段，制药公司需提交相关文件和资料，包括药物的成分、制造工艺、药理学和临床试验数据等，以向监管机构声明该药物的安全性和有效性。在注册阶段，制药公司需要向相应的监管机构提交申请文件，并经过审查、评估和批准过程，以获得。</span
            >
          </p>
        </div>
      </div>

      <div class="content-header">
        <img class="content-icon" src="@/assets/icon_wenda_2@2x.png" />
        <span class="content-title">翻译助手怎么使用效果最好</span>
      </div>
      <div class="content-answer2">
        <div style="margin: 20px">
          <p>
            <span class="answer-text">这里是回答，这里是回答。</span>
          </p>
          <p>
            <span class="answer-text"
              >在药品生产与研发领域的药物语境中，“Declaration and
              Registration”指的是药品的申报和注册过程。这是指制药公司在准备上市新的药物或药品之前需要进行的一系列程序。在申报阶段，制药公司需提交相关文件和资料，包括药物的成分、制造工艺、药理学和临床试验数据等，以向监管机构声明该药物的安全性和有效性。在注册阶段，制药公司需要向相应的监管机构提交申请文件，并经过审查、评估和批准过程，以获得。</span
            >
          </p>
        </div>
      </div>

      <div class="content-header">
        <img class="content-icon" src="@/assets/icon_wenda_3@2x.png" />
        <span class="content-title">翻译助手怎么使用效果最好</span>
      </div>
      <div class="content-answer3">
        <div style="margin: 20px">
          <p>
            <span class="answer-text">这里是回答，这里是回答。</span>
          </p>
          <p>
            <span class="answer-text"
              >在药品生产与研发领域的药物语境中，“Declaration and
              Registration”指的是药品的申报和注册过程。这是指制药公司在准备上市新的药物或药品之前需要进行的一系列程序。在申报阶段，制药公司需提交相关文件和资料，包括药物的成分、制造工艺、药理学和临床试验数据等，以向监管机构声明该药物的安全性和有效性。在注册阶段，制药公司需要向相应的监管机构提交申请文件，并经过审查、评估和批准过程，以获得。</span
            >
          </p>
        </div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="100"
        layout="prev, pager, next, jumper"
        :total="1000"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { loginOut, userInfo } from "@/api/index";
import { wxPayPre, queryOrder, getOrderList } from "@/api/order";
import QRCode from "qrcode";

export default {
  data() {
    return {
      urls: [
        "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg",
      ],
      user: {
        money: null,
        mobileNo: null,
        headImg: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      userInfo().then((response) => {
        this.user = response.MobileBody;
        if (this.user.headImg == null) {
          this.user.headImg =
            "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg";
        }
      });
      getOrderList(this.queryParams).then((response) => {
        this.moneyData = response.MobileBody.orderList;
      });
    },
  },
};
</script>

<style scoped>
.title-card {
  width: 100%;
  background-color: #fafafa;
}

.box-card {
  margin: 20px;
  width: 97%;
}

.exit {
  padding: 3px 0;
  width: 80px;
  height: 30px;
  border-radius: 5px;
}

.clearfix {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.title-image {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
}

.name-text {
  font-size: 14px;
  margin-right: 30px;
}

.content-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
}

.button-content {
  margin-right: 0;
}

.button-font {
  font-size: 10px;
}

.button-in {
  color: #1f75e8;
}

.button-font-in {
  font-size: 10px;
  color: #1f75e8;
}

.information {
  height: 40px;
  margin: 5px;
  display: flex;
  padding: 15px;
}

.infor-text {
  width: 65%;
  margin: -10px 0 0 5px;
  font-size: 12px;
}

.count-text {
  margin-bottom: -10px;
}

.content {
  height: 824px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px;
}

.content-header {
  display: flex;
  align-items: center;
}
.content-icon {
  width: 40px;
  height: 40px;
  margin: 20px;
}

.content-title {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 25px;
}

.content-answer1 {
  width: 1054px;
  background: #effcfb;
  margin: 0 80px;
}

.content-answer2 {
  width: 1054px;
  background: #edf4fe;
  margin: 0 80px;
}

.content-answer3 {
  width: 1054px;
  background: #fff8ee;
  margin: 0 80px;
}

.answer-text {
  width: 1013px;
  height: 120px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 30px;
}

.el-pagination {
  text-align: center;
  margin-top: 10px;
}
</style>
