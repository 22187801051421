<template>
  <!-- <div id="login-page">
    <div class="login-container">
      <img class="image" src="https://gxpresource.oss-cn-shenzhen.aliyuncs.com/login_img.png" />
      <div class="container">
        <img class="login_image" src="/pic/login_logo@2x.png" />
        <div class="title">登录</div>
        <el-form
                ref="loginForm"
                :model="loginForm"
                :rules="loginRules"
                label-width="80px"
        >
          <el-form-item label="手机号" prop="mobileNo">
            <el-input v-model="loginForm.mobileNo"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="verificationCode">
            <div class="code-container">
              <el-input v-model="loginForm.verificationCode"></el-input>
              <div class="code-btn-container">
                <el-button
                        v-if="!countdown"
                        @click.prevent="sendCode"
                        class="code"
                >发送验证码</el-button>
                <span v-else class="countdown">{{ countdown }}s</span>
              </div>
            </div>
          
          
           
          </el-form-item>
          <div class="btn-container">
            <el-button type="primary" @click.prevent="submitForm">登录</el-button>
            <el-button @click.prevent="wechatLogin">微信登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="text">琼ICP备19003923号</div>
  </div> -->
  <div class="login">
		<div class="login-weaper">
			<div class="login-left"></div>
			<div class="login-right">
				<div class="login-main">
					<h4 class="login-title">GxpCode</h4>
					<el-form class="el-form login-form" ref="loginForm" :model="loginForm" :rules="loginRules">
						<el-form-item prop="mobileNo" style="margin-left: 0px">
							<el-input type="text" maxlength="11" prefix-icon="el-icon-phone-outline" v-model="loginForm.mobileNo" clearable autocomplete="off"> </el-input>
						</el-form-item>
						<el-form-item style="margin-left: 0px" prop="verificationCode">
							<div class="el-row" span="24">
								<div class="el-col el-col-14">
									<el-input
										type="text"
										maxlength="6"
										prefix-icon="el-icon-position"
										v-model="loginForm.verificationCode"
										clearable
										autocomplete="off"
									></el-input>
								</div>
								<div class="el-col el-col-10">
									<div class="login-code">
                    <el-button @click.prevent="sendCode" v-if="!countdown"> 发送验证码</el-button>
										<span v-else class="countdown">{{ countdown }}s</span>
									</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item style="margin: 40px 0px 0">
							<el-button type="primary" class="login-submit" @click="submitForm" >
								<span>登录</span>
							</el-button>
						</el-form-item>
						<el-form-item style="margin: 10px 0px 0">
							<el-button class="login-submit" @click="wechatLogin" >
								<span>微信登录</span>
							</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<div class="text">琼ICP备19003923号</div>
		<div class="vue-particles">
			<vue-particles color="#dedede" shapeType="star" linesColor="#dedede" hoverMode="grab" clickMode="push" style="height: 100%"></vue-particles>
		</div>
	</div>

 
</template>

<script>
  import { getMobileCode, login, wxLoginCode } from "@/api/index";

  export default {
    data() {
      return {
        loginForm: {
          mobileNo: undefined,
          verificationCode: "",
        },
        loginRules: {
          mobileNo: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "手机号格式不正确",
              trigger: "blur",
            },
          ],
          verificationCode: [
            { required: true, message: "请输入验证码", trigger: "blur" },
          ],
        },
        countdown: 0,
        inviteForm: {
          invitationCode: "",
        },
      submit: {
				loading: false,
			},
      };
    },
    created() {
      this.inviteForm.invitationCode = this.getParams("invitationCode");
      if (this.inviteForm.invitationCode === null) {
        this.inviteForm.invitationCode = "";
      }
    },
    methods: {
      sendCode() {
        if (this.loginForm.mobileNo !== undefined) {
          getMobileCode(this.loginForm).then((response) => {
            console.log(response.data);
          });
          // 发送短信验证码逻辑
          // 倒计时开始
          this.countdown = 60;
          const timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(timer);
            }
          }, 1000);
        }
      },
      submitForm() {
        this.submit.loading = true;
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            // let token =
            //   "gxpcodeeyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJneHBjb2RlIiwib3Blbl9pZCI6ImE3MDIwMjFjOTBjYjQ4NTZiOWEzZGRlOGJmYTQwNDM0IiwidXNlcl9pZCI6ImE3MDIwMjFjOTBjYjQ4NTZiOWEzZGRlOGJmYTQwNDM0IiwiaWF0IjoxNjg5NDc0MDYyLCJleHAiOjE2OTAwNzg4NjJ9.HZ8owJiGQbmmAti9k-3m15bitrOIdO8K83WpCZZTc68";
            // localStorage.setItem("token", token);
            // this.$router.push({ path: "/" });
            login(this.loginForm).then((response) => {
              let token = response.MobileBody.token;
              localStorage.setItem("token", token);
              this.$router.push({ path: "/" });
            });
          } else {
            return false;
          }
        });
      },
      wechatLogin() {
        // alert(this.inviteForm.invitationCode);
        wxLoginCode(this.inviteForm).then((response) => {
          let loginUrl = response.MobileBody.codeUrl;
          window.location.href = loginUrl;
        });
      },
      getParams(str) {
        const url = window.location.href.split("?")[1];
        if (url !== undefined) {
          var attrs = url.split("&");
          for(var i in attrs) {
            var value = attrs[i].split("=");
            if (str === value[0]) {
              return value[1];
            }
          }
        }
        return "";
      }
    },
  };
</script>

<style scoped lang="scss">
  // #login-page {
  //   width: 100%;
  //   height: 100vh;
  //   background-image: url("https://gxpresource.oss-cn-shenzhen.aliyuncs.com/img_bg.png");
  //   background-size: cover;
  //   background-position: center;
  // }

  // .image {
  //   height: 90%;
  //   margin: 20px;
  //   flex: 1;
  // }

  // .container {
  //   float: left;
  //   margin: 20px;
  //   flex: 1;
  // }

  // .login_image {
  //   height: 50px;
  //   width: 126px;
  //   margin: 0 25%;
  //   margin-bottom: 40px;
  //   text-align: center;
  // }

  // .title {
  //   font-family: HarmonyOS_Sans_SC_Bold;
  //   font-size: 20px;
  //   /* font-weight: bold; */
  //   line-height: 28px;
  //   letter-spacing: 0px;
  //   color: #000000;
  //   margin: 10px;
  // }

  // .el-form-item {
  //   margin: 30px 0;
  // }

  // .el-input {
  //   width: 90%;
  // }

  // .el-button {
  //   width: 100px;
  // }

  // .code {
  //   margin: 0 25px 0 15px;
  //   padding: 12px;
  // }

  // .login-container {
  //   width: 60%;
  //   margin: 75px 20%;
  //   float: left;
  //   background-color: white;
  //   height: 400px;
  //   display: flex;
  // }

  // .btn-container {
  //   margin-top: 20px;
  //   text-align: center;
  // }

  // .code-container {
  //   display: flex;
  //   align-items: center;
  // }

  // .countdown {
  //   margin-left: 10px;
  //   color: #999;
  // }

  // .wechat-login-container {
  //   margin-top: 20px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // .text {
  //   font-size: 14px;
  //   width: 100%;
  //   height: 20px;
  //   text-align: center;
  //   bottom: 20px;
  //   position: relative;
  // }

  // @media (max-width: 768px) {
  //   /* 在屏幕宽度小于等于768px时应用的样式 */
  //   #login-page {
  //     background-size: contain;
  //   }
  //   .login-container {
  //     width: 100%;
  //     margin: 0;
  //     height: auto;
  //     flex-direction: column;
  //   }
  //   .image {
  //     height: 300px;
  //     margin: 20px 0;
  //     flex: none;
  //   }
  //   .container {
  //     float: none;
  //     margin: 20px 0;
  //     flex: none;
  //   }
  //   .title {
  //     font-size: 16px;
  //     margin-left: 20px;
  //   }
  //   .el-input {
  //     width: 100%;
  //   }
  //   .el-button {
  //     width: 100%;
  //     margin: 10px 0;
  //   }
  //   .code {
  //     margin: 0;
  //   }
  //   .btn-container {
  //     margin-top: 10px;
  //   }
  //   .text {
  //     font-size: 12px;
  //   }
  // }

  .login {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	position: relative;
	.vue-particles {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background: radial-gradient(ellipse at top left, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
		background-image: url('https://gxpresource.oss-cn-shenzhen.aliyuncs.com/img_bg.png');
		background-size: cover;
		background-position: center;
	}
	.text{
		position: absolute;
		bottom:60px;
		left: 50%;
		transform: translate(-50%,-50%);
		font-size: 14px;
		color: var(--prev-color-text-primary);
		z-index: 999;
	}
	.login-weaper {
		margin:75px auto;
		height: 400px;
		display: flex;
		box-sizing: border-box;
		position: relative;
		z-index: 1;
		border: none;
		box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
		// width: 60%;
		.login-left {
			width: 491px;
			padding: 20px;
			// font-size: 16px;
			// color: var(--prev-color-text-white);
			position: relative;
			// background-color: var(--prev-color-primary);
			background-image: url('https://gxpresource.oss-cn-shenzhen.aliyuncs.com/login_img.png');
			background-size: cover;
			background-position: center;
			display: flex;
			flex-direction: column;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			.login-left-box {
				flex: 1;
				overflow: hidden;
				position: relative;
				z-index: 1;
				display: flex;
				align-items: center;
				padding: 80px 45px;
				
				
			}
		}
		.login-right {
			width: 491px;
			padding: 20px;
			position: relative;
			align-items: center;
			display: flex;
			background-color: var(--prev-bg-white);
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			.login-main {
				margin: 0 auto;
				width: 70%;
				.login-title {
					color: var(--prev-color-primary);
					margin-bottom: 40px;
					font-weight: 500;
					font-size: 22px;
					text-align: center;
					letter-spacing: 4px;
				}
				.login-form {
					margin: 10px 0;
					i {
						color: var(--prev-color-text-primary);
					}
					.el-form-item {
						margin-bottom: 20px !important;
						.login-code {
							display: flex;
							align-items: center;
							justify-content: space-around;
							margin: 0 0 0 20px;
							user-select: none;
							
						}
						.login-submit {
							width: 100%;
							letter-spacing: 2px;
						}
					}
				}
			}
		}
	}
}
</style>
