// 定义内容
export default {
	card: {
		title1: 'My desk',
		title2: 'Message notification',
		title3: 'more',
		title4: 'Marketing recommendation',
		title5: 'more',
		title6: 'Inventory operations',
		title7: 'Performance',
		title8: 'Out of stock monitoring',
		title9: 'Performance overtime warning',
	},
};
