<template>
  <div id="app">
    <router-view/>
    <Setings ref="setingsRef" />
  </div>
</template>
<script>
import Setings from '@/layout/navBars/topBar/setings.vue';
export default {
    name: 'App',
    components: { Setings },
    mounted() {
		this.openSetingsDrawer();
	},
    methods: {
		// 布局配置弹窗打开
		openSetingsDrawer() {
			this.bus.$on('openSetingsDrawer', () => {
				this.$refs.setingsRef.openDrawer();
			});
		},
	},
    destroyed() {
		this.bus.$off('openSetingsDrawer');
	},
}
</script>

<style>

</style>
