<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="英文" prop="en">
        <el-input v-model="queryParams.en" placeholder="请输入英文" clearable @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item label="中文" prop="cn">
        <el-input v-model="queryParams.cn" placeholder="请输入中文" clearable @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item label="解释" prop="explain">
        <el-input v-model="queryParams.explain" placeholder="请输入解释" clearable @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate">修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="single" @click="handleDelete">删除</el-button>
      </el-col>
<!--      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-download" size="mini" @click="handleExport">导出</el-button>
      </el-col>-->
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-plus" size="mini" @click="handleInsertRedis">插入缓存</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-delete" size="mini" @click="deleteFanYiPromptRedis">删除缓存</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="promptList" stripe height="700" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键ID" align="center" prop="id" />
      <el-table-column label="英文" align="center" prop="en" />
      <el-table-column label="中文" align="center" prop="cn" />
      <el-table-column label="解释" align="center" prop="explain" />
      <el-table-column label="创建时间" align="center" prop="createTime" />
      <el-table-column label="更新时间" align="center" prop="updateTime" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改【请填写功能名称】对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="英文" prop="en">
          <el-input v-model="form.en" placeholder="请输入英文" />
        </el-form-item>
        <el-form-item label="中文" prop="cn">
          <el-input v-model="form.cn" placeholder="请输入中文" />
        </el-form-item>
        <el-form-item label="解释" prop="explain">
          <el-input v-model="form.explain" placeholder="请输入解释" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listPrompt, getPrompt, delPrompt, addPrompt, updatePrompt, handleInsertRedis, deleteFanYiPromptById,deleteFanYiPromptRedis } from "@/api/prompt";

export default {
  name: "Prompt",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 【请填写功能名称】表格数据
      promptList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        en: null,
        cn: null,
        explain: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询【请填写功能名称】列表 */
    getList() {
      this.loading = true;
      listPrompt(this.queryParams).then(response => {
        this.promptList = response.MobileBody.promptList;
        this.total = response.MobileBody.totalCount;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        en: null,
        cn: null,
        explain: null,
        createTime: null,
        updateTime: null
      };
    },
    // 表单重置
    resetForm() {
      this.form = {
        id: null,
        en: null,
        cn: null,
        explain: null,
        createTime: null,
        updateTime: null
      };
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加【请填写功能名称】";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.queryParams.id = row.id;
      getPrompt(this.queryParams).then(response => {
        this.form = response.MobileBody;
        this.open = true;
        this.title = "修改prompt";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updatePrompt(this.form).then(response => {
              this.$message("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addPrompt(this.form).then(response => {
              this.$message("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除编号为"' + row.id + '"的数据项？').then(function() {
        console.log('222---------'+JSON.stringify(row.id));
        return deleteFanYiPromptById(row.id);
      }).then(() => {
        this.getList();
        this.$message("删除成功");
      }).catch(() => {});
    },
    /** 插入redis */
    handleInsertRedis() {
      this.$confirm('是否需要把所有数据项插入Redis缓存？').then(function() {
        return handleInsertRedis();
      }).then(() => {
        this.getList();
        this.$message("插入Redis成功");
      }).catch(() => {});
    },
    /** 插入redis */
    deleteFanYiPromptRedis() {
      this.$confirm('是否需要把所有Redis缓存数据项删除？').then(function() {
        return deleteFanYiPromptRedis();
      }).then(() => {
        this.getList();
        this.$message("删除Redis成功");
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('system/prompt/export', {
        ...this.queryParams
      }, `prompt_${new Date().getTime()}.xlsx`)
    }
  }
};
</script>
